import { FC } from 'react'

import { HelmetProps } from './types'

import { Footer } from 'components'

const HelmetLayout: FC<HelmetProps> = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
)

export default HelmetLayout
