import { type FC, useEffect, useState } from 'react'
import classNames from 'classnames'

import Time from './Time'
import type { TTimerComponentProps } from './types'
import styles from './TimerComponent.module.scss'

import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'

export const timeLeft = (targetDate: string) => {
  const currentDate = new Date()
  const targetDay = new Date(targetDate)

  const days = differenceInDays(targetDay, currentDate)
  const hours = differenceInHours(targetDay, currentDate) % 24
  const minutes = differenceInMinutes(targetDay, currentDate) % 60
  const seconds = differenceInSeconds(targetDay, currentDate) % 60

  return [
    { time: String(days), name: 'Days' },
    { time: String(hours).padStart(2, '0'), name: 'Hours' },
    { time: String(minutes).padStart(2, '0'), name: 'Minutes' },
    { time: String(seconds).padStart(2, '0'), name: 'Seconds' },
  ]
}

const TimerComponent: FC<TTimerComponentProps> = ({ date, className = '', itemClassName = '' }) => {
  const [timeInfo, setTimeInfo] = useState(timeLeft(date))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeInfo(timeLeft(date))
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div
      role='button'
      onClick={() => timeLeft(date)}
      className={classNames(styles.wrapper, { [className]: className })}
    >
      <h2 className={styles.title}>Starting In</h2>
      <div className={styles.wrapper__content}>
        {timeInfo.map((element, index) => (
          <Time className={itemClassName} date={element.time} key={index} name={element.name} />
        ))}
      </div>
    </div>
  )
}
export default TimerComponent
