import type { FC } from 'react'
import classNames from 'classnames'

import type { TTimeProps } from '../types'
import styles from './Time.module.scss'

import { useResponsive } from 'hooks'

const Time: FC<TTimeProps> = ({ date, name, isPreSale = false, className = '' }) => {
  const { isTabletLarge } = useResponsive()

  return (
    <div className={classNames(styles.wrapper, { [styles.pre_sale]: isPreSale, [className]: className })}>
      <span className={classNames(styles.wrapper__date, { [styles.pre_sale__date]: isPreSale })}>{date ?? 0}</span>
      <p>{isTabletLarge ? name[0] : name}</p>
    </div>
  )
}

export default Time
