import type { FC } from 'react'

import styles from './Footer.module.scss'
import Logo from 'assets/images/logo.png'
import AwsIcon from 'assets/images/powered-by-aws.png'

import { ReactComponent as TelegramIcon } from '../../assets/svg/social/telegram.svg'
import { ReactComponent as EmailIcon } from '../../assets/svg/social/email.svg'
import { ReactComponent as MeetupIcon } from '../../assets/svg/social/meetup.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/svg/social/youtube.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/svg/social/linkedin.svg'

const Footer: FC = () => (
  <footer id='FOOTER' className={styles.wrapper}>
    <div className='container'>
      <img src={Logo} className={styles.logo} />

      <p className={styles.title}>Join Us</p>
      <div className={styles.share}>
        <a className={styles.share_item} href='https://t.me/AWSUGArmeniaChannel' target='_blanck'>
          <TelegramIcon />
        </a>
        <a
          className={`${styles.share_item} ${styles.share_item_linkedin}`}
          href='https://www.linkedin.com/company/aws-user-group-armenia/?viewAsMember=true '
          target='_blanck'
        >
          <LinkedinIcon />
        </a>
        <a
          className={`${styles.share_item} ${styles.share_item_meet}`}
          href='https://www.meetup.com/aws-user-group-armenia/'
          target='_blanck'
        >
          <MeetupIcon />
        </a>
        <a className={styles.share_item} href='https://www.youtube.com/@AWSUGArmenia/about' target='_blanck'>
          <YoutubeIcon />
        </a>
        <a className={styles.share_item} href='mailto:awsugarmenia@gmail.com'>
          <EmailIcon />
        </a>
      </div>
      <div className={styles.footer}>
        <p className={styles.text}>
          © 2025, AWS User Group Armenia. All trademarks are the property of respective owners.
        </p>
        <img src={AwsIcon} alt='powered by AWS' />
      </div>
    </div>
  </footer>
)

export default Footer
