// import BagratPhoto from 'assets/images/team/Bagrat.png'

import ChristinePhoto from 'assets/images/team/Christine.png'
import ElenPhoto from 'assets/images/team/Elen.png'
import GayanePhoto from 'assets/images/team/Gayane.png'
import HenoPhoto from 'assets/images/team/Heno.png'
import HovoPhoto from 'assets/images/team/Hovo.png'
import LinaPhoto from 'assets/images/team/Lina.png'
import TigranPhoto from 'assets/images/team/Tigran.png'
import VahePhoto from 'assets/images/team/Vahe.png'

const teamList = [
  {
    id: 3,
    image: ChristinePhoto,
    title: 'Kristine Armiyants',
    subtitle: 'Organizer, Founder & Leader at AWS UG Armenia, AWS Community Builder',
    link: 'https://www.linkedin.com/in/kristine-armiyants-42b69b12b/',
    style: {
      objectPosition: 'unset',
    },
  },
  {
    id: 2,
    image: TigranPhoto,
    title: 'Tigran Gevorgyan',
    subtitle: 'Organizer, AWS Community Builder, Core Team Member at AWS User Group Armenia',
    link: 'https://www.linkedin.com/in/tigran-gevorgyan/',
  },
  {
    id: 9,
    image: VahePhoto,
    title: 'Vahe Momjyan',
    subtitle: 'Organizer, AWS Academy Accredited Instructor',
    link: 'https://www.linkedin.com/in/vahemomjyan/',
    style: {
      objectPosition: 'unset',
    },
  },

  // {
  //   id: 1,
  //   image: BagratPhoto,
  //   title: 'Bagrat Harutyunyan',
  //   subtitle: 'Organizer, AWS Community Builder',
  //   link: 'https://www.linkedin.com/in/bagrath1206/',
  // },

  {
    id: 4,
    image: ElenPhoto,
    title: 'Elen Yeghiazaryan',
    subtitle: 'Organizer, Core Team Member at AWS User Group Armenia',
    link: 'https://www.linkedin.com/in/elen-yeghiazaryan-111530197/',
    style: {
      objectPosition: 'center',
    },
  },
  {
    id: 7,
    image: HovoPhoto,
    title: 'Hovhannes Mikayelyan',
    subtitle: 'Organizer, Core Team Member at AWS UG Armenia',
    link: 'https://www.linkedin.com/in/hovmikayelyan/',
  },
  {
    id: 8,
    image: LinaPhoto,
    title: 'Lina Adamyan',
    subtitle: 'Organizer',
    link: 'https://www.linkedin.com/in/adamianlina/',
  },
  {
    id: 6,
    image: HenoPhoto,
    title: 'Henrik Armenakyan',
    subtitle: 'Organizer',
    link: 'https://www.linkedin.com/in/henrik-armenakyan-46aa8a1a6/',
    style: {
      objectPosition: 'unset',
    },
  },
  {
    id: 5,
    image: GayanePhoto,
    title: 'Gayane Martoyan',
    subtitle: 'Organizer',
    link: 'https://www.linkedin.com/in/gayane-martoyan/',
  },
]

export default teamList
